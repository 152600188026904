import React, { useState } from 'react';
import { Menu } from '@tmap/mmm-style-guide/src/Menu';
import SideMenu from '@tmap/mmm-style-guide/src/SideMenu';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Logout } from '@tmap/mmm-style-guide/src/Icon';
import { useAuth0 } from '@auth0/auth0-react';

const ProfileMenu = (props) => {
  const user = useAuth0();

  const [anchorEl, setAnchorEl] = useState(null);
  const showMenu = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }
  const handleLogout = () => {
    handleClose();
    user.logout({ returnTo: window.location.origin });
  }
  const StyledMenu = styled(Menu)(({ theme }) => ({
    padding: theme.spacing(1)
  }))


  return (
    <>
      <div onClick={handleOpen}>{props.children}</div>
      <StyledMenu anchorEl={anchorEl} keepMounted open={showMenu} onClose={handleClose}>
        <SideMenu.Item Icon={Logout} onClick={handleLogout}>
          <SideMenu.ItemText>Logout</SideMenu.ItemText>
        </SideMenu.Item>
      </StyledMenu>
    </>
  )
}

export default ProfileMenu;