import React from 'react';
import { Avatar } from '@tmap/mmm-style-guide/src/Avatar';
import { Person } from '@tmap/mmm-style-guide/src/Icon';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import imageUrlBuilder from '@sanity/image-url';
import client from '../client';
import useCurrentUser from '../hooks/useCurrentUser';

const builder = imageUrlBuilder(client);

const StyledPicture = styled('picture')(({ theme }) => ({
  width: '100%'
}))
const StyledImg = styled('img')(({ theme }) => ({
  width: '100%'
}))

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (p) => p !== 'size'
})(({ theme, size }) => ({
  width: size + 'px',
  height: size + 'px',
}))

const ProfileAvatar = (props) => {
  const {
    size,
    onClick
  } = props;
  const user = useCurrentUser();
  return (
    <StyledAvatar onClick={onClick} size={size}>
      {user && user.profileImage ?
        <StyledPicture>
          <source srcSet={builder.image(user.profileImage).format('webp').width(size).height(size).url()} />
          <StyledImg src={builder.image(user.profileImage).width(size).height(size).url()} />
        </StyledPicture>
        :
        <Person style={{ fontSize: (size * 0.6) + 'px' }} />
      }
    </StyledAvatar>
  )
}


export default ProfileAvatar;